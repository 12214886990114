<template>
  <component
    :is="as"
    :to="isRouterLink && target"
    :href="isRegularLink && target"
    :disabled="disabled"
    :class="cssClasses.buttonClasses"
    @click.prevent.stop="click"
    ref="button"
    :style="{ width: actualWidth }"
    :event="!isDisabled && isRouterLink ? 'click' : null"
    :tabindex="isDisabled ? -1 : 0"
    :aria-hidden="isDisabled"
  >
    <VueText as="span" :class="cssClasses.leftIconClass" v-if="!!iconName">
      <VueIcon
        :width="iconSize.width"
        :height="iconSize.height"
        :iconName="iconName"
        :iconColor="iconColor"
      />
    </VueText>

    <slot />

    <VueText as="span" :class="cssClasses.rightIconClass" v-if="rightIcon">
      <VueIcon
        :width="rightIcon.width"
        :height="rightIcon.height"
        :iconName="rightIcon.name"
        :iconColor="iconColor"
      />
    </VueText>
  </component>
</template>
<script>
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import VueText from '@/components/shared/VueText/VueText.vue';

import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import { variationValidator } from '@/utils/validators/propValidators.js';

export default {
  name: 'VueButton',
  props: {
    color: {
      type: String,
      // validator: variationValidator,
      default: 'default',
    },
    isSingleLine: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
    },
    contentAlignment: {
      type: String,
      default: FLEX_JUSTIFIES.start,
      validator: value => variationValidator(FLEX_JUSTIFIES, value),
    },
    as: {
      type: String,
      default: 'button',
    },
    target: {
      type: String,
    },
    outlined: {
      type: Boolean,
    },
    ghost: {
      type: Boolean,
    },
    size: {
      type: String,
      default: COMPONENT_CONSTANTS.COMPONENT_SIZES.medium,
      validator: value => variationValidator(COMPONENT_CONSTANTS.COMPONENT_SIZES, value),
    },
    isIconButton: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
    },
    rightIcon: {
      type: Object,
    },
    iconColor: {
      type: String,
      default: '#000',
    },
    iconSize: {
      type: Object,
      default: () => ({
        width: 18,
        height: 18,
      }),
    },
  },
  components: {
    VueIcon,
    VueText,
  },
  computed: {
    cssClasses() {
      const buttonClasses = [
        this.$style.button,
        this.$style.ripple,
        this.$style[`button-content-justify-${this.contentAlignment}`],
      ];

      if (this.outlined || this.ghost) {
        buttonClasses.push(this.$style.outlined);
      }
      if (this.ghost) {
        buttonClasses.push(this.$style.ghost);
      } else {
        buttonClasses.push(this.$style[`button-${this.size}`]);
      }

      if (this.isSingleLine) {
        buttonClasses.push(this.$style['single-line']);
      }
      buttonClasses.push(this.$style[this.color]);
      if (this.disabled) {
        buttonClasses.push(this.$style.disabled);
      }
      let leftIconClass = [];
      let rightIconClass = [];
      if (this.iconName) {
        leftIconClass.push(this.$style['icon-holder-left']);
      }
      if (!this.isIconButton) {
        leftIconClass.push(this.$style['icon-holder-space']);
      }
      if (this.rightIcon) {
        rightIconClass.push(this.$style['icon-holder-right']);
      }

      return { buttonClasses, leftIconClass, rightIconClass };
    },
    actualWidth() {
      return this.$refs.button ? `${this.$refs.button.getBoundingClientRect().width}px` : null;
    },
    isDisabled() {
      return this.disabled;
    },
    isRouterLink() {
      return this.as === 'router-link';
    },
    isRegularLink() {
      return this.as === 'a';
    },
  },
  methods: {
    click(e) {
      if (this.isRegularLink && this.isDisabled) {
        e.preventDefault();
        e.stopPropagation();
      }

      if (!this.isDisabled) {
        this.$emit('click', e);
      }
    },
  },
};
</script>

<style module lang="scss">
@import '~@/styles/styles';

.button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: $button-padding;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  text-transform: $button-text-transform;
  min-width: $button-min-width;
  position: relative;
  overflow: hidden;
  font-family: $button-font-family;
  font-size: $button-font-size;
  font-weight: $button-font-weight;
  border-radius: $button-border-radius;
  box-shadow: $button-shadow;
  transition: $button-transition;
  transition-property: box-shadow, background-color;
  height: $button-height;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  text-decoration: $button-text-decoration;
  outline: none;

  &:active {
    box-shadow: $button-active-shadow;
    text-decoration: $button-hover-text-decoration;
  }

  &:hover {
    text-decoration: $button-hover-text-decoration;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    opacity: $button-disabled-opacity;
    cursor: not-allowed;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
}
.single-line {
  white-space: nowrap;
}
.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, $brand-primary 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform $animation-transition-duration * 3,
      opacity $animation-transition-duration * 5;
  }

  &:active:before {
    transform: scale(0, 0);
    opacity: 0.2;
    transition: 0s;
  }
}

@each $variation, $values in $button-variations {
  .#{$variation} {
    color: map-get($values, 'color');
    background: map-get($values, 'bg');
    border: map-get($values, 'border');

    &:hover {
      background: map-get($values, 'hover-bg');
      color: map-get($values, 'hover-color');
      border-color: map-get($values, 'hover-bg');
    }
  }

  .outlined {
    &.#{$variation} {
      color: map-get($values, 'bg');

      &:hover {
        border-color: map-get($values, 'hover-bg');
        color: map-get($values, 'hover-bg');
      }
    }
  }
}

@each $variation, $values in $button-size-variations {
  .button-#{$variation} {
    padding: map-get($values, 'padding');
    font-size: map-get($values, 'font-size');
    border-radius: map-get($values, 'border-radius');
  }
}

@each $variation, $justify in $palette-flex-justifies {
  .button-content-justify-#{$justify} {
    justify-content: $justify;
  }
}

.outlined {
  background: transparent;

  &:hover {
    background: transparent;
  }
}

.ghost {
  display: inline-flex;
  width: auto;
  border: none;
  padding: 0 !important;

  &:hover {
    border: none !important;
  }
}

@mixin icon-holder {
  display: inline-flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.icon-holder-left {
  @include icon-holder;
}
.icon-holder-space {
  margin-right: palette-space-level(8);
}
.icon-holder-right {
  @include icon-holder;
  margin-left: auto;
}
</style>
