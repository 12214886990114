<template>
  <div
    :class="cssComp"
    :style="{
      backgroundImage: image ? `url(${image})` : '',
      minWidth: `${width}px`,
      width: `${width}px`,
      height: `${height}px`,
    }"
  ></div>
</template>
<script>
export default {
  name: 'BrandLogo',

  props: {
    image: {
      type: String,
    },
    width: {
      type: Number,
      default: 60,
    },
    height: {
      type: Number,
      default: 60,
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    cssComp() {
      let cssClass = [this.$style[`logo`]];
      if (this.hasBorder) {
        cssClass.push(this.$style[`logo-bordered`]);
      }
      if (this.image) {
        cssClass.push(this.$style[`logo-loaded`]);
      }
      return cssClass;
    },
  },
};
</script>
<style module lang="scss">
.logo {
  background-size: 92%;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 0.35s ease-in-out;
  &-bordered {
    border: 1px solid palette-color-level('grey', '20');
    border-radius: palette-radius-level('1');
  }
  &-loaded {
    opacity: 1;
  }
}
</style>
