<template>
  <div>
    <div class="auth-form-footer">
      <BrandLogo
        :hasBorder="false"
        :width="111"
        :height="60"
        :image="require('@/assets/logos/philip-morris-logo.png')"
      />
      <VueText sizeLevel="3" weightLevel="1" class="text" color="grey-30">
        PMAktif portal, tacir/esnaf niteliği taşıyan yalnızca Türkiye’deki satış noktalarına yönelik
        olarak hazırlanmıştır. Siteyi kullanmak istiyorsanız, satış temsilciniz ile iletişime
        geçiniz.</VueText
      >
    </div>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo.vue';

export default {
  name: 'BrandAuthFormFooter',
  components: { VueText, BrandLogo },
};
</script>

<style lang="scss">
.auth-form-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: palette-space-level(20) 0;

  .text {
    max-width: 300px;
    word-break: break-word;
    line-height: 1.67;
  }
}
</style>
