import RoutesRoot from '@/router/routes/RoutesRoot.js';
import RoutesLogin from '@/router/routes/authentication/RoutesLogin.js';
import RoutesAthentication from '@/router/routes/RoutesAthentication.js';
import { Account } from '@/services/Api/index';
import StorageHelper from '@/utils/storageHelper';
import AccountMixin from '@/utils/accountUtils.js';
import { clearAppData } from '@/mixins/storageUtils.js';
import { UserTokenRoles } from '@/constants/userTokenRoles.js';

const LoginMixin = {
  mixins: [AccountMixin],
  methods: {
    processLogin({ taxNumber, customerNumber, phoneNumber, password, firstLogin }) {
      // Check the submit where it comes from
      let loginType;
      let number;
      let storageFieldName;
      let eventLoginType = 'login';
      if (taxNumber) {
        loginType = 'loginWithVKN';
        storageFieldName = 'VUE_APP_STORAGE_CURRENT_USER_VKN_KEY';
        number = taxNumber;
        eventLoginType += '_vkn';
      } else if (customerNumber) {
        loginType = 'loginWithCustomerCode';
        storageFieldName = 'VUE_APP_STORAGE_CURRENT_USER_CN_KEY';
        number = customerNumber;
        eventLoginType += '_customer';
      } else {
        loginType = 'loginWithPhoneNumber';
        storageFieldName = 'VUE_APP_STORAGE_CURRENT_USER_PN_KEY';
        number = phoneNumber;
        if (firstLogin) {
          eventLoginType += '_first';
        } else {
          eventLoginType += '_phone';
        }
      }

      // Send login type to Account service
      Account[loginType](number, password).then(res => {
        const { Data } = res ? res.data : { Data: null };

        this.$store.dispatch('auth/setAuthData', Data).then(() => {
          if (Data) {
            // Fetch the storage field which number user sent
            new StorageHelper({
              id: process.env[storageFieldName],
            }).set(this.rememberMe ? number : null);

            const { token, refreshToken, user, pos, staffValidation } = Data;

            if (token) {
              new StorageHelper({ id: process.env.VUE_APP_STORAGE_TOKEN_KEY }).set(token);
            }

            if (Data.tokenRole === UserTokenRoles.ExpiredPassword) {
              let isAuth = Data.tokenRole === UserTokenRoles.ExpiredPassword;
              this.$router.push({
                name: `expired-password`,
                params: { isAuth },
              });
              return;
            }

            if (refreshToken) {
              new StorageHelper({ id: process.env.VUE_APP_STORAGE_REFRESH_TOKEN_KEY }).set(
                refreshToken.token,
              );
            }
            if (user) {
              new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY }).set(user);
            }

            if (pos) {
              new StorageHelper({ id: process.env.VUE_APP_STORAGE_LOGIN_TYPE }).set(eventLoginType);
              const posArr = Array.isArray(pos) ? pos : [pos];

              if (posArr.length > 1) {
                this.$store.dispatch('auth/setPosList', posArr).then(() => {
                  this.$router.push(
                    `${RoutesRoot.Authentication.path}/${RoutesAthentication.Login.path}/${RoutesLogin.SelectPos.path}`,
                  );
                });
              } else {
                new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_POS_KEY }).set(
                  posArr[0],
                );
                this.accountLogin(posArr[0].posChannel, staffValidation, user);
              }
            }

            if (!staffValidation) {
              // Added this to remove for google password managers.
              [
                'input[name=password]',
                'input[name=phoneNumber]',
                'input[name=customerNumber]',
                'input[name=vkn]',
              ].forEach(item => {
                if (document.querySelector(item)) {
                  document.querySelector(item).remove();
                }
              });
            }
          }
        });
      });
    },
    async handleLoginRequest() {
      const isValid = await this.$refs.form.$refs.observer.validate();
      if (!isValid) {
        return;
      } else {
        this.submitted = true;
      }
      await this.processLogin(this.formModel);
    },
    processLogout() {
      clearAppData(
        'logout',
        `${RoutesRoot.Authentication.path}/${RoutesAthentication.Login.path}/${RoutesLogin.SelectLoginType.path}`,
      );
    },
  },
};

export default LoginMixin;
