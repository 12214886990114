<template>
  <div :class="cssClasses"><slot /></div>
</template>

<script>
export default {
  name: 'VueGridCol',
  props: {
    size: {
      type: Number,
    },
    alignment: {
      type: String,
    },
  },
  computed: {
    cssClasses() {
      const classes = [this.$style.col];
      if (this.size) {
        classes.push(this.$style[`col-${this.size}`]);
      }
      if (this.alignment) {
        classes.push(this.$style[`col-align-${this.alignment}`]);
      }
      return classes;
    },
  },
};
</script>

<style lang="scss" module>
@import '~@/styles/styles';

.col {
  flex-basis: 0;
  flex-grow: 1;
  position: relative;
  width: 100%;
  max-width: 100%;
  padding: $col-padding;
}

@each $variation, $size in $column-variations {
  .col-#{$variation} {
    flex: 0 0 $size;
    max-width: $size;
  }
}

@each $variation, $alignment in $palette-flex-alignments {
  .col-align-#{$variation} {
    align-self: $alignment !important;
  }
}
</style>
