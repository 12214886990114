<template>
  <div :class="cssClasses"><slot /></div>
</template>

<script>
export default {
  name: 'VueGridRow',
  props: {
    vAlignment: {
      type: String,
    },
    hAlignment: {
      type: String,
    },
  },
  computed: {
    cssClasses() {
      const classes = [this.$style.row];

      if (this.vAlignment) {
        classes.push(this.$style[`row-align-${this.vAlignment}`]);
      }
      if (this.hAlignment) {
        classes.push(this.$style[`row-justify-${this.hAlignment}`]);
      }

      return classes;
    },
  },
};
</script>

<style lang="scss" module>
@import '~@/styles/styles';

.row {
  display: flex;
  flex-wrap: wrap;
  margin: $row-margin;
}

@each $variation, $alignment in $palette-flex-alignments {
  .row-align-#{$variation} {
    align-items: $alignment !important;
  }
}
@each $variation, $justify in $palette-flex-justifies {
  .row-justify-#{$variation} {
    justify-content: $justify !important;
  }
}
</style>
