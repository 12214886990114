<template>
  <ValidationObserver :slim="true" ref="observer" v-slot="{ passes }">
    <div :class="cssClasses.formWrapperClasses">
      <form>
        <div :class="cssClasses.formBody">
          <slot></slot>
        </div>
        <div :class="cssClasses.formFooter">
          <slot name="footer"></slot>
        </div>
      </form>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

export default {
  name: 'FormLoginWithCustomerNumber',
  props: {
    isInline: {
      type: Boolean,
      default: false,
    },
    bodyPadding: {
      type: Array,
      default: () => ['0'],
    },
    footerPadding: {
      type: Array,
      default: () => ['0'],
    },
  },
  components: {
    ValidationObserver,
  },
  computed: {
    cssClasses() {
      const formWrapperClasses = [this.$style['form-wrapper']];
      const formBody = [this.$style['form-body']];
      const formFooter = [this.$style['form-footer']];

      formWrapperClasses.push(
        !this.isInline ? this.$style['form-wrapper-column'] : this.$style['form-wrapper-row'],
      );

      return {
        formWrapperClasses,
        formBody,
        formFooter,
      };
    },
  },
  methods: {},
};
</script>

<style module lang="scss">
.form-wrapper {
  display: flex;

  &.form-wrapper-row {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &.form-wrapper-column {
    flex-direction: column;

    form {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
