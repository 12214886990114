export const variationValidator = (sourceVariations, value) => {
  let sourceArray = [];
  if (Array.isArray(sourceVariations)) {
    sourceArray = [...sourceVariations];
  } else if (sourceVariations === Object(sourceVariations)) {
    // check if is object
    sourceArray = Object.keys(sourceVariations).map(key => sourceVariations[key]);
  }

  return sourceArray.includes(value.toLowerCase());
};
