import StorageHelper from '@/utils/storageHelper';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import RoutesSecure from '@/router/routes/RoutesSecure.js';
import RoutesLogin from '@/router/routes/authentication/RoutesLogin.js';
import RoutesAthentication from '@/router/routes/RoutesAthentication.js';
import { ACCOUNT_TYPES } from '@/constants/component.constants.js';
import StaticContent from '@/services/Api/staticContents';
import { Account } from '@/services/Api/index';
import { clearAppData } from '@/mixins/storageUtils.js';

const AccountMixin = {
  data() {
    return {
      channelContent: null,
      modalConfig: {
        isModalOpened: true,
        modalTitle: '',
        isDoubleButton: true,
        modalType: 'confirmation',
        firstButtonText: 'EVET',
        secondButtonText: 'HAYIR',
        modalConfirmationText: '',
        closeButton: false,
        closeOnOverlayClick: false,
      },
    };
  },
  computed: {
    isUserKacc() {
      let accType = new StorageHelper({ id: process.env.VUE_APP_STORAGE_USER_ACCOUNT_TYPE }).get();
      return accType && (accType === ACCOUNT_TYPES.kacc || accType === ACCOUNT_TYPES.lamp);
    },
    isQrReaderPage() {
      if (
        this.$route.meta &&
        this.$route.meta.appbarConfig &&
        this.$route.meta.appbarConfig.isQrReaderPage
      )
        return true;
      else {
        return false;
      }
    },
    isRackPage() {
      if (
        this.$route.meta &&
        this.$route.meta.appbarConfig &&
        this.$route.meta.appbarConfig.isRackPage
      )
        return true;
      else {
        return false;
      }
    },
  },
  methods: {
    rejectKaccLogin() {
      let rejectConfirmModal = {
        ...this.modalConfig,
        modalText: this.channelContent.secondContent,
        firstButtonFn: () => {
          //log KACC delete account approval and logout
          Account.validateStaff(false).then(() => {
            this.confirmDelete();
          });
        },
        secondaryButtonFn: () => {
          this.accountLogin(ACCOUNT_TYPES.kacc);
        },
      };
      this.$store.dispatch('app/setModalConfig', null).then(() => {
        this.$store.dispatch('app/setModalConfig', rejectConfirmModal);
      });
    },
    confirmDelete() {
      //log KACC approve  request false then
      new StorageHelper({ id: process.env.VUE_APP_STORAGE_KACC_LOGOUT_INFO }).set(true);
      this.processLogout();
    },
    confirmLogin() {
      let loginConfirmModal = {
        ...this.modalConfig,
        modalText: this.channelContent.content,
        firstButtonFn: () => {
          Account.validateStaff(true).then(res => {
            const { Data } = res ? res.data : { Data: null };
            this.$store.dispatch('auth/setAuthData', Data).then(() => {
              if (Data) {
                const { token, refreshToken, user, selectedPos, staffValidation } = Data;

                if (token) {
                  new StorageHelper({ id: process.env.VUE_APP_STORAGE_TOKEN_KEY }).set(token);
                }
                if (refreshToken) {
                  new StorageHelper({ id: process.env.VUE_APP_STORAGE_REFRESH_TOKEN_KEY }).set(
                    refreshToken.token,
                  );
                }
                if (user) {
                  new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY }).set(user);
                }
                if (selectedPos) {
                  new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_POS_KEY }).set(
                    selectedPos,
                  );
                  window.location.reload();
                }
                if (staffValidation) {
                  this.$store.dispatch('auth/setAfterLogin', true);
                  user && user.isImageRecognitionUser
                    ? this.$router.push(
                        `${RoutesRoot.Secure.path}/${RoutesSecure.ImageRecognition.path}`,
                      )
                    : this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.Feed.path}`);
                }
              }
            });
          });
        },
        secondaryButtonFn: () => {
          this.rejectKaccLogin();
        },
      };
      this.$store.dispatch('app/setModalConfig', null).then(() => {
        this.$store.dispatch('app/setModalConfig', loginConfirmModal);
      });
    },
    accountLogin(userType, staffValidation, user) {
      new StorageHelper({ id: process.env.VUE_APP_STORAGE_USER_ACCOUNT_TYPE }).set(userType);

      if (!staffValidation) {
        if (!this.channelContent) {
          StaticContent.getChannelContent().then(res => {
            this.channelContent = res.data.Data;
            this.confirmLogin();
          });
        } else {
          this.confirmLogin();
        }
      } else {
        this.$store.dispatch('auth/setAfterLogin', true);
        user.isImageRecognitionUser
          ? this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.ImageRecognition.path}`)
          : this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.Feed.path}`);
      }
    },
    processLogout() {
      clearAppData(
        'logout',
        `${RoutesRoot.Authentication.path}/${RoutesAthentication.Login.path}/${RoutesLogin.SelectLoginType.path}`,
      );
    },
  },
};

export default AccountMixin;
